<template>
<div>

<Loading :loading="loading" />

<h4 class="title is-4">View Form</h4>

<div class="container">
  <object v-if="url" :data="url" type="application/pdf" width="100%" height="700" />
</div>
<hr>
<p>To print or download, use the tools within the PDF display window.</p>

<div class="notification is-warning" v-if="error">
  {{ error }}
</div>

</div>
</template>

<script>
import { api, parseError } from '@/modules/util'

export default {
  name: 'Form',
  props: ['user'],
  data() {
    return {
      loading: true,
      url: '',
      error: '',
    }
  },
  methods: {
    async init() {
      try {
        this.loading = true
        const res = await api.get(`/forms/${this.$route.params.id}/view`)
        this.url = res.data.url
        this.error = ''
        this.loading = false
      } catch (err) {
        this.error = parseError(err)
        this.loading = false
      }
    }
  },
  mounted() {
    this.init()
  }
  
}
</script>